exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-author-index-jsx": () => import("./../../../src/templates/Author/index.jsx" /* webpackChunkName: "component---src-templates-author-index-jsx" */),
  "component---src-templates-category-index-jsx": () => import("./../../../src/templates/Category/index.jsx" /* webpackChunkName: "component---src-templates-category-index-jsx" */),
  "component---src-templates-homepage-index-jsx": () => import("./../../../src/templates/Homepage/index.jsx" /* webpackChunkName: "component---src-templates-homepage-index-jsx" */),
  "component---src-templates-page-index-jsx": () => import("./../../../src/templates/Page/index.jsx" /* webpackChunkName: "component---src-templates-page-index-jsx" */),
  "component---src-templates-post-index-jsx": () => import("./../../../src/templates/Post/index.jsx" /* webpackChunkName: "component---src-templates-post-index-jsx" */),
  "component---src-templates-releases-index-jsx": () => import("./../../../src/templates/Releases/index.jsx" /* webpackChunkName: "component---src-templates-releases-index-jsx" */)
}

