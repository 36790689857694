import Footer from '../Footer'

import './reset.scss'

function Layout({ children }) {
  return (
    <>
      <div>
        <div id="fb-root" />

        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
