import { Provider, ErrorBoundary } from '@rollbar/react'
import Layout from './src/components/Layout'

const rollbarToken = process.env.GATSBY_ROLLBAR_TOKEN
const environment = process.env.NODE_ENV

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  const rollbarConfig = {
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {element}
      </ErrorBoundary>
    </Provider>
  )
}
