import { Link } from 'gatsby'
import SocialLinks from '../SocialLinks'
import gaEvent from 'utils/gaEvent'

import * as s from './style.module.scss'

const links = [
  { label: 'Redakcja', path: '/redakcja' },
  { label: 'O stonerze', path: '/o-stonerze' },
  { label: 'Współpraca', path: '/wspolpraca' },
  { label: 'Poprzednie numery', path: '/archiwum' },
]

function trackLinkClick(label) {
  gaEvent('click', 'Footer link', label)
}

const Footer = () => {
  return (
    <footer className={s.container} data-testid="footer">
      <div className={s.content}>
        <ul className={s.links}>
          {links.map(({ label, path }) => (
            <li>
              <Link
                to={path}
                className={s.link}
                onClick={() => trackLinkClick(label)}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>

        <SocialLinks trackLinkClick={trackLinkClick} />

        <ul className={s.copy}>
          <li>&copy; Stonerpolski 2019-2024</li>
          <li>
            Design by{' '}
            <strong>
              <a
                href="https://pl.linkedin.com/in/marta-nowak-13090b17b"
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => trackLinkClick('design - Marta')}
              >
                Marta
              </a>
            </strong>{' '}
            | Code by{' '}
            <strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/kubaploskonka/"
                onClick={() => trackLinkClick('code - Kuba')}
              >
                Kuba
              </a>
            </strong>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
