import FbIcon from '../../img/fb.svg'
import InstaIcon from '../../img/insta.svg'

function SocialLinks({ trackLinkClick }) {
  return (
    <ul className="social-links">
      <li>
        <a
          href="https://www.facebook.com/stonerpolski/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
          onClick={() => trackLinkClick('Facebook')}
        >
          <FbIcon className="social-icon" />
        </a>
      </li>

      <li>
        <a
          href="https://www.instagram.com/stonerpolski/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
          onClick={() => trackLinkClick('Instagram')}
        >
          <InstaIcon className="social-icon" />
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks
